<template>
    <div style="width:100%;min-height:200px;float:left;position:relative;">
      <div class="leftArrow" @click="pictureClicked()">
        <CIcon name="cil-chevron-left"/>
      </div>
      <app-img v-if="!picLoading" style="max-height:200px;max-width:100%;" :lazy-src="$apiAdress + '/storage/hat_images/' + item.customer_style + '-' + pictureShown + '.jpg'"></app-img>
      <div class="rightArrow" @click="pictureClicked()">
        <CIcon name="cil-chevron-right"/>
      </div>

      <hr style="width:40%;height:2px;margin-right:30%;background-color:#e0e0e0;float:left;margin-left:30%;margin-top:15px;margin-bottom:15px;" />
    </div>
</template>
<script>
import AppImg from './../inventory/app_img'
var methods = {}

methods.pictureClicked = function () {
  if (this.pictureShown === 'FRONT') {
    this.pictureShown = 'BACK';
  } else {
    this.pictureShown = 'FRONT'
  }
  this.picLoading = true
  var that = this
  setTimeout(function () {
    that.picLoading = false
  }, 500)
}

export default {
    // ===Component name
    name: "ImageSummaryWrapper",
    // ===Props passed to component
    props: ['item'],
    // ===Components used by this component
    components: {
      AppImg
    },
    // ====component Data properties
    data(){
        return{
          picLoading: false,
          pictureShown: 'FRONT'
        }
    },
    // ===Code to be executed when Component is mounted
    mounted() {

    },
    // ===Computed properties for the component
    computed: {},
    // ===Component methods
    methods: methods
}
</script>
<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped lang="scss">
  .rightArrow {
    position: absolute;
    right:5px;
    width:50px;
    height:50px;
    cursor: pointer;
    top: 75px;
    line-height: 50px;
  }

  .leftArrow {
    position: absolute;
    left:5px;
    width:50px;
    height:50px;
    cursor: pointer;
    top: 75px;
    line-height: 50px;
  }
</style>
